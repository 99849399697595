import request from '@/utils/request';

export function fetchAll() {
  return request({
    url: 'v1/banner/list',
    method: 'get',
  });
}

export function create(data) {
  return request({
    url: 'v1/banner/create',
    method: 'post',
    data,
  });
}

export function deactivate(data) {
  return request({
    url: 'v1/banner/deactivate',
    method: 'post',
    data,
  });
}

export function setPrimary(data) {
  return request({
    url: 'v1/banner/setPrimary',
    method: 'post',
    data,
  });
}
