<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Banners</span>
      <el-button class="btn-add" type="mini" @click="showAddDialog()">Add</el-button>
    </el-card>
    <div class="table-container">
      <el-table ref="Table" style="width: 100%" :data="list" v-loading="listLoading" border>
        <el-table-column label="Image">
          <template slot-scope="scope"><img :src="scope.row.imageUrl" style="width: 300px" /></template>
        </el-table-column>
        <el-table-column label="Primary">
          <template slot-scope="scope">
            <el-checkbox v-if="scope.row.active" :value="scope.row.isPrimary" @change="setPrimary(scope.row)">
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Operation">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" v-if="scope.row.active" @click="deactivate(scope.row)">Deactivate
            </el-button>
            <!-- <el-button
              type="primary"
              size="mini"
              v-if="scope.row.active"
              @click="edit(scope.row)"
              >Edit</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :title="bannerParam.id == null ? 'Add Banner' : 'Edit Banner'" :visible.sync="addVisible" width="30%">
        <el-form>
          <el-form-item label="Image: ">
            <single-upload v-model="bannerParam.imageUrl" auto-complete="off"></single-upload>
          </el-form-item>
          <el-form-item label="Active: ">
            <el-checkbox v-model="bannerParam.active"></el-checkbox>
          </el-form-item>
          <el-form-item label="Primary: ">
            <el-checkbox v-model="bannerParam.isPrimary"></el-checkbox>
          </el-form-item>
          <el-form-item label="Deep Link Type">
            <el-select v-model="bannerParam.deepLinkParam.path" @change="clearDeepLinkParam()">
              <el-option v-for="item in deeplinkOptions" :value="item.path" :key="item.path" :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Custom URL">
            <el-input v-model="bannerParam.deepLinkParam.path"></el-input>
          </el-form-item>
          <el-form-item label="Merchant" v-if="
          bannerParam.deepLinkParam.path == ProductDetailLink ||
          bannerParam.deepLinkParam.path == DigitalProductDetailLink">
            <el-select v-model="selectMerchantId">
              <el-option v-for="item in merchantOptions" :value="item.id" :key="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Merchant" v-if="bannerParam.deepLinkParam.path == MerchantDetailLink">
            <el-select v-model="bannerParam.deepLinkParam.params.id">
              <el-option v-for="item in merchantOptions" :value="item.id" :key="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Category" v-if="bannerParam.deepLinkParam.path == CategoryDetailLink">
            <el-select v-model="bannerParam.deepLinkParam.params.id">
              <el-option v-for="item in categoryOptions" :value="item.id" :key="item.id" :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Badge" v-if="bannerParam.deepLinkParam.path == BadgeHistoryLink">
            <el-select v-model="bannerParam.deepLinkParam.params.id">
              <el-option v-for="item in badgeOptions" :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Product" v-if="
            (bannerParam.deepLinkParam.path == ProductDetailLink ||
              bannerParam.deepLinkParam.path ==
              DigitalProductDetailLink) &&
            !productOptionsLoading
          ">
            <el-select v-model="bannerParam.deepLinkParam.params.id" collapse-tags>
              <div class="el-input">
                <input type="text" class="el-input__inner" v-model="productSearchValue" @keyup="
                  handleFilter(
                    productOptions,
                    'productOptionsFiltered',
                    productSearchValue,
                    'bannerParam.deepLinkParam.params.id'
                  )
                " />
              </div>
              <el-option v-for="item in productOptionsFiltered" :value="item.id" :key="item.id" :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitCreate()">Submit</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  fetchAll, create, deactivate, setPrimary,
} from '@/api/banner';
import { fetchOptions as fetchMerchantOptions } from '@/api/merchant';
import { fetchOptions as fetchCategoryOptions } from '@/api/productCate';
import { fetchOptions as fetchProductOptions } from '@/api/product';
import SingleUpload from '@/components/Upload/singleUpload';
import { fetchList as fetchBadgeList } from '@/api/badge';

const defaultBannerParam = {
  id: null,
  imageUrl: null,
  active: true,
  isPrimary: false,
  deepLinkParam: {
    path: null,
    params: {
      id: null,
    },
  },
};

const ProductDetailLink = '/link/v1/productDetail';
const DigitalProductDetailLink = '/link/v1/digitalProductDetail';
const MerchantDetailLink = '/link/v1/merchantDetail';
const CategoryDetailLink = '/link/v1/categoryDetail';
const BadgeHistoryLink = '/link/v1/badgeHistory';
export default {
  name: 'banner',
  components: { SingleUpload },
  data() {
    return {
      list: null,
      listLoading: true,
      addVisible: false,
      bannerParam: { ...defaultBannerParam },
      deeplinkOptions: [
        {
          name: 'None / Custom URL',
          path: null,
        },
        {
          name: 'Product',
          path: ProductDetailLink,
        },
        {
          name: 'Digital Product',
          path: DigitalProductDetailLink,
        },
        {
          name: 'Merchant',
          path: MerchantDetailLink,
        },
        {
          name: 'Category',
          path: CategoryDetailLink,
        },
        {
          name: 'Badge',
          path: BadgeHistoryLink,
        },
      ],

      selectMerchantId: null,
      merchantOptions: [],

      selectCategoryId: null,
      categoryOptions: [],

      badgeOptions: [],

      productSearchValue: null,
      productOptions: null,
      productOptionsFiltered: null,
      productOptionsLoading: true,

      ProductDetailLink,
      DigitalProductDetailLink,
      MerchantDetailLink,
      CategoryDetailLink,
      BadgeHistoryLink,
    };
  },
  created() {
    this.getList();
    this.fetchMerchantOptions();
    this.fetchCategoryOptions();
    fetchBadgeList().then((response) => {
      this.badgeOptions = response.data;
    });
  },
  watch: {
    selectMerchantId(newValue) {
      if (newValue != null) this.fetchProductOptions();
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      fetchAll().then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    submitCreate() {
      create(this.bannerParam).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.getList();
      });
      this.addVisible = false;
    },
    showAddDialog() {
      this.bannerParam = { ...defaultBannerParam };
      this.addVisible = true;
    },
    setPrimary(item) {
      if (item.isPrimary) {
        this.$message({
          message: '请设置其他图片为Primary',
          type: 'warning',
          duration: 1000,
        });
      } else {
        this.$confirm('设置此图片为Primary?', 'Warning', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'Warning',
        }).then(() => {
          setPrimary({ id: item.id }).then(() => {
            this.$message({
              message: 'Success',
              type: 'success',
              duration: 1000,
            });
            this.getList();
          });
        });
      }
    },
    deactivate(item) {
      deactivate({ id: item.id }).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.getList();
      });
    },
    edit(item) {
      this.bannerParam = item;
      this.addVisible = true;
    },
    clearDeepLinkParam() {
      this.selectMerchantId = null;
      this.bannerParam.deepLinkParam.params = { id: null };
      this.productOptionsLoading = true;
    },
    fetchMerchantOptions() {
      fetchMerchantOptions().then((response) => {
        this.merchantOptions = response.data;
      });
    },
    fetchCategoryOptions() {
      fetchCategoryOptions().then((response) => {
        this.categoryOptions = response.data;
        this.categoryOptions.sort((x, y) => (x.name > y.name ? 1 : -1));
      });
    },
    fetchProductOptions() {
      this.productOptionsLoading = true;
      const params = {
        merchantId: this.selectMerchantId,
        productType: null,
      };
      if (this.bannerParam.deepLinkParam.path == ProductDetailLink) {
        params.productType = 'PHYSICAL_PRODUCT';
      } else if (
        this.bannerParam.deepLinkParam.path == DigitalProductDetailLink
      ) {
        params.productType = 'DIGITAL_PRODUCT';
      } else return;
      fetchProductOptions(params).then((response) => {
        this.productOptions = response.data;
        this.productOptionsFiltered = response.data;
        this.productOptionsLoading = false;
      });
    },
    handleFilter(src, dst, keyword) {
      if (keyword == null || keyword == '') return;
      const matched = src.filter((x) => x.name.toLowerCase().includes(keyword.toLowerCase()));
      const unmatched = src.filter(
        (x) => !x.name.toLowerCase().includes(keyword.toLowerCase()),
      );
      this[dst] = matched.concat(unmatched);
    },
  },
};
</script>
